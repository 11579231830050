@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);

html {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    min-height: 100vh;
    background-image: url(/static/media/dries-augustyns-_oxIMhN9FiA-unsplash.d6196d4b.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
}


h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #5E5E5E;
    margin-bottom: 0;
}

p {
    margin-top: 0;
    font-size: 18px;
    line-height: 33px;
    text-align: center;
    color: #B8B8B8;
}

button {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #767676;
    background: none;
    width: 145px;
    cursor: pointer;
    border: none;
}

button:active, button:focus {
    outline: none;
}

.App {
    display: flex;
    justify-content: center;
    padding: 15px;
}

.avatar {
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    width: 150px;
    margin-top: 10px;
}

.avatar > img {
    object-fit: cover;
    height: 150px;
}

.bar, .box {
    background-color: #f5f5ff;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.2);
    transition: all 1s ease;
}

.bar {
    width: 289.96px;
    height: 452.6px;
}

.links {
    display: flex;
    justify-content: center;
}

.link-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #84B779;
    border-radius: 15px;
    margin: 15px;
    padding: 5px;
    transition: all 200ms ease;
    width: 55px;
    height: 55px;
    color: #f5f5ff;
    text-decoration: none;
}

.link-button:hover {
    transform: scale(1.1);
}

.line {
    background-color: #767676;
    height: 2px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
    width: 50px;
}

.about > .line {
    width: 90px;
}
.about:hover .line {
    width: 105px;
}

.back > .line {
    width: 80px;
}
.back:hover .line {
    width: 95px;
}

.link-button > img {
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.about, .back {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 300ms ease;
}

#back, #about {
    font-weight: bold;
}

#waffle {
    font-size: 17px;
    color: #5E5E5E;
    line-height: 27px;
    margin-top: 20px;
    margin-bottom: 15px;
}

